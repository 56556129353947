import { useCallback, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom, geographySearchAtom } from '../atoms';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useComponentControls } from '../hooks/useComponentControls';
import { useGetPlaceAutocompletePlaceholder } from '../hooks/useGetPlaceAutocompletePlaceholder';

import {
  isDistanceSearchGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';

import { isValidPlaceChecklistSelection } from '../utils/entity';

import { useIsGeographyFeatureEnabled } from '../FeaturesProvider';
import { GeographyTag } from '../GeographyTag';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { PlaceCheckList } from '../PlaceCheckList';
import { SearchOptions } from '../SearchOptions';

import css from './styles.module.scss';

interface DesktopGeographySearchProps {
  error: boolean;
  hasResultCountButton?: boolean;
}

export function DesktopGeographySearch({
  error = false,
  hasResultCountButton,
}: DesktopGeographySearchProps) {
  const { closeSearchOptions, openSearchOptions, openSearchModal } =
    useComponentControls();
  const searchOptionsRef = useRef<HTMLDivElement>(null);
  const { searchOptionsOpen } = useAtomValue(geographyComponentStateAtom);
  const [geographyData, setGeographyData] =
    useAtomicStateStateAtom(geographySearchAtom);
  const [isPlaceCheckListOpen, setIsPlaceCheckListOpen] = useState(false);
  const getPlaceAutocompletePlaceholder =
    useGetPlaceAutocompletePlaceholder(isPlaceCheckListOpen);

  const isGeographyFeatureEnabled = useIsGeographyFeatureEnabled();
  const isAreaSearchEnabled = isGeographyFeatureEnabled('areaSearch');
  const isDistanceSearchEnabled = isGeographyFeatureEnabled('distanceSearch');

  const handleGeographyTagClick = useCallback(() => {
    if (!geographyData) return;

    closeSearchOptions();

    if (
      isPlaceSearchGeography(geographyData) &&
      isValidPlaceChecklistSelection(geographyData)
    ) {
      setIsPlaceCheckListOpen(true);

      return;
    }

    if (isDistanceSearchGeography(geographyData)) {
      openSearchModal('DISTANCE');

      return;
    }

    openSearchModal('AREA');
  }, [geographyData, closeSearchOptions, openSearchModal]);

  useClickOutside(searchOptionsRef, closeSearchOptions, searchOptionsOpen);

  // This useCallback is needed since this function is a dependency of a
  // useEffect in usePlaceAutocomplete hook. Please, do not remove it.
  const handleSetGeography = useCallback(
    (isValidPlaceChecklistGeography: boolean) => {
      if (isValidPlaceChecklistGeography) {
        setIsPlaceCheckListOpen(true);
      }
    },
    []
  );

  return (
    <>
      <PlaceAutocomplete error={error} onSetGeography={handleSetGeography}>
        <PlaceAutocomplete.InputContainer>
          {geographyData ? (
            <GeographyTag
              onClick={handleGeographyTagClick}
              onRemove={() => setGeographyData(null)}
              customClass={css['in-desktopGeographySearch__maxWidthTag']}
            />
          ) : (
            <PlaceAutocomplete.Icon />
          )}

          <PlaceAutocomplete.Input
            placeholder={getPlaceAutocompletePlaceholder}
            onClick={openSearchOptions}
            onInput={closeSearchOptions}
          />
        </PlaceAutocomplete.InputContainer>

        <PlaceAutocomplete.SuggestionList />

        {isPlaceCheckListOpen && (
          <PlaceCheckList onClickOutside={() => setIsPlaceCheckListOpen(false)}>
            {hasResultCountButton && (
              <PlaceCheckList.ResultCountButton
                onResultClick={() => {
                  setIsPlaceCheckListOpen(false);
                }}
              />
            )}
          </PlaceCheckList>
        )}
      </PlaceAutocomplete>

      {searchOptionsOpen && (
        <SearchOptions ref={searchOptionsRef}>
          <SearchOptions.CurrentSearch />
          <SearchOptions.SelectOnMap />
          {isAreaSearchEnabled && <SearchOptions.DrawAreaOnMap />}
          {isDistanceSearchEnabled && <SearchOptions.DistanceFromAPoint />}
        </SearchOptions>
      )}
    </>
  );
}
