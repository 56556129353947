import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';

import { useTrack } from 'src/tracking/hooks/useTrack';
import { EventName } from 'src/tracking/utils/enums';

export const usePlaceCheckListButtonTracking = () => {
  const track = useTrack();
  const { user } = useAuthContext();

  const trackPlaceCheckListButtonPressed = useCallback(() => {
    track(EventName.SEARCHBAR_ZONES_BUTTON_PRESSED, {
      'Is User Logged': Boolean(user),
      'User ID': user?.uuid,
    });
  }, [track, user]);

  return {
    trackPlaceCheckListButtonPressed,
  };
};
