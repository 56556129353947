import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useComponentControls } from './hooks/useComponentControls';

import type { GeographySearchFeatureToggles } from './types';

import { AlphaAlertButton } from './AlphaAlertButton';
import { DesktopGeographySearch } from './DesktopGeographySearch';
import { FeaturesProvider } from './FeaturesProvider';
import { MobileGeographySearch } from './MobileGeographySearch';
import { SearchModal } from './SearchModal';

import css from './styles.module.scss';

const isAlphaAlertEnabled = isFeatureEnabled(
  'REACT_GEOGRAPHY_SEARCH_ALPHA_ALERT'
);

interface ReactGeographySearchProps {
  featureToggles: GeographySearchFeatureToggles;
  error?: boolean;
  hasResultCountButton?: boolean;
  customClass?: string;
}

export function ReactGeographySearch({
  featureToggles,
  error = false,
  customClass,
  hasResultCountButton,
}: ReactGeographySearchProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <FeaturesProvider features={featureToggles}>
      <div className={clsx(css['in-geographySearch'], customClass)}>
        {maxScreenWidthSm ? (
          <MobileGeographySearch
            error={error}
            featureToggles={featureToggles}
          />
        ) : (
          <DesktopGeographySearch
            error={error}
            hasResultCountButton={hasResultCountButton}
          />
        )}

        {isAlphaAlertEnabled && <AlphaAlertButton />}
      </div>

      <SearchModal featureToggles={featureToggles} />
    </FeaturesProvider>
  );
}

// Useful for controlling this component from the outside
export const useGeographySearchControls = useComponentControls;

export type { GeographySearchFeatureToggles };
