import { useCallback, useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useAtomValue } from 'jotai';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import { usePlaceCheckListButtonTracking } from '../../hooks/usePlaceCheckListButtonTracking';

import css from './styles.module.scss';

interface ResultCountButtonProps {
  onResultClick: () => void;
}

export function ResultCountButton({ onResultClick }: ResultCountButtonProps) {
  const { trans } = useTranslations();
  const { resultsCount, isFetching } = useAtomValue(searchInfoAtom) || {};
  const formatNumber = useNumberFormatterCallback();

  const resultsCountLabel = useMemo(() => {
    if (isFetching) return;

    if (resultsCount && resultsCount > 0) {
      return trans('act_show_all_result', {
        count: resultsCount,
        params: [formatNumber(resultsCount, 0)],
      });
    }

    return trans('act_no_result');
  }, [formatNumber, isFetching, resultsCount, trans]);

  const { trackPlaceCheckListButtonPressed } =
    usePlaceCheckListButtonTracking();

  const handleResultClick = useCallback(() => {
    //todo @russo: valutare se inserire la funzione deferCallback per evitare INP
    trackPlaceCheckListButtonPressed();
    onResultClick();
  }, [onResultClick, trackPlaceCheckListButtonPressed]);

  return (
    <div className={css['in-resultCountButton']}>
      <PepitaButton
        variant="accent"
        onClick={handleResultClick}
        customClass={css['in-resultCountButton__button']}
        loading={isFetching}
      >
        {resultsCountLabel}
      </PepitaButton>
    </div>
  );
}
