import { useCallback, useRef } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom, geographySearchAtom } from '../atoms';

import { useComponentControls } from '../hooks/useComponentControls';
import { useGetPlaceAutocompletePlaceholder } from '../hooks/useGetPlaceAutocompletePlaceholder';

import { isDistanceSearchGeography } from 'src/libs/geography';

import type { GeographySearchFeatureToggles } from '../types';

import {
  FeaturesProvider,
  useIsGeographyFeatureEnabled,
} from '../FeaturesProvider';
import { GeographyTag } from '../GeographyTag';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { SearchOptions } from '../SearchOptions';
import { SearchOptionModal } from '../SearchOptionsModal';

import css from './styles.module.scss';

interface MobileGeographySearchProps {
  featureToggles: GeographySearchFeatureToggles;
  error: boolean;
}

export function MobileGeographySearch({
  featureToggles,
  error = false,
}: MobileGeographySearchProps) {
  const autocompleteInputRef = useRef<HTMLInputElement>(null);
  const {
    closeSearchOptionsModal,
    openSearchOptionsModal,
    openSearchModal,
    closeSearchOptions,
  } = useComponentControls();
  const { searchOptionsModalOpen } = useAtomValue(geographyComponentStateAtom);
  const [geographyData, setGeographyData] =
    useAtomicStateStateAtom(geographySearchAtom);
  const getPlaceAutocompletePlaceholder =
    useGetPlaceAutocompletePlaceholder(false);

  const isGeographyFeatureEnabled = useIsGeographyFeatureEnabled();
  const isAreaSearchEnabled = isGeographyFeatureEnabled('areaSearch');
  const isDistanceSearchEnabled = isGeographyFeatureEnabled('distanceSearch');

  const handleGeographyTagClick = useCallback(() => {
    if (!geographyData) return;

    closeSearchOptions();

    if (isDistanceSearchGeography(geographyData)) {
      openSearchModal('DISTANCE');

      return;
    }

    openSearchModal('AREA');
  }, [geographyData, closeSearchOptions, openSearchModal]);

  const handleSearchByNameSelect = () => {
    if (geographyData) {
      openSearchModal('PLACE');
    } else {
      autocompleteInputRef.current?.focus();
    }
  };

  return (
    <>
      <PlaceAutocomplete error={error}>
        <PlaceAutocomplete.InputContainer>
          {geographyData ? (
            <GeographyTag
              onClick={handleGeographyTagClick}
              onRemove={() => setGeographyData(null)}
            />
          ) : (
            <PlaceAutocomplete.Icon />
          )}

          <PlaceAutocomplete.Input
            placeholder={getPlaceAutocompletePlaceholder}
            onClick={openSearchOptionsModal}
          />
        </PlaceAutocomplete.InputContainer>
      </PlaceAutocomplete>

      {searchOptionsModalOpen && (
        <SearchOptionModal onClose={closeSearchOptionsModal}>
          <FeaturesProvider features={featureToggles}>
            <PlaceAutocomplete
              customClass={css['in-mobileGeographySearch__autocomplete']}
              inputRef={autocompleteInputRef}
              hasFixedHeight
            >
              <PlaceAutocomplete.InputContainer
                customClass={css['in-mobileGeographySearch__inputContainer']}
              >
                {geographyData ? (
                  <GeographyTag
                    onClick={handleGeographyTagClick}
                    onRemove={() => setGeographyData(null)}
                    hasFixedHeight
                    customClass={css['in-mobileGeographySearch__tag']}
                  />
                ) : (
                  <>
                    <PlaceAutocomplete.Icon hasLeftSpace showBackIcon />

                    <PlaceAutocomplete.Input
                      placeholder={getPlaceAutocompletePlaceholder}
                    />
                  </>
                )}
              </PlaceAutocomplete.InputContainer>

              <PlaceAutocomplete.SuggestionList />
            </PlaceAutocomplete>

            <SearchOptions>
              <SearchOptions.CurrentSearch />
              <SearchOptions.SearchByName onSelect={handleSearchByNameSelect} />
              {isAreaSearchEnabled && <SearchOptions.DrawAreaOnMap />}
              {isDistanceSearchEnabled && <SearchOptions.DistanceFromAPoint />}
            </SearchOptions>
          </FeaturesProvider>
        </SearchOptionModal>
      )}
    </>
  );
}
